import { Box } from '@chakra-ui/react';
import React from 'react';

import { AppFooter } from '@app/components/AppFooter';
import { AppHeader } from '@app/components/AppHeader';
import Metadata, { MetadataProps } from '@app/components/Metadata';

import s from './MainLayout.module.scss';

interface MainLayoutProps {
  children: React.ReactNode;
  metadata?: MetadataProps;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children, metadata }) => {
  return (
    <Box className={s.root}>
      <Metadata {...metadata} />
      <AppHeader />
      <div className={s.main}>{children}</div>
      <AppFooter />
    </Box>
  );
};

export default MainLayout;

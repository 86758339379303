import process from 'process';

export const config = {
  modeEnv: process.env.NEXT_PUBLIC_MODE_ENV,
  appUrl: process.env.NEXT_PUBLIC_APP_URL,
  documentation: 'https://codex-finance.gitbook.io/codex-finance/',
  discord: 'https://discord.gg/VaWCzGCQK3',
  twitter: 'https://twitter.com/codexfi',
  telegram: 'https://t.me/CODEX_Dexchange',
  github: 'https://github.com/codex-finance'
};

import { NextPage } from 'next';
import React from 'react';

import MainLayout from '@app/components/MainLayout';
import { APP_NAME } from '@app/constants/common';

import s from 'src/styles/Landing.module.scss';
import { InfoBox } from '@app/components/InfoBox';
import { Pipeline } from '@app/components/Pipeline';
import { Supporters } from '@app/components/Supporters';

export const LandingPage: NextPage = () => {
  return (
    <MainLayout metadata={{ title: `${APP_NAME}` }}>
      <div className={s.root}>
        <div className={s.title}>Simple, Robust DeFi for people. 😎</div>
        <Supporters />
        <div className={s.content}>
          <InfoBox
            title="Decentralized FrontEnd"
            text="Following industry best practices, even the FrontEnd of the App is hosted on the Blockchain(ICP)."
          />
          <InfoBox
            title="Secured by BTC miners via Core Protocol"
            text="If to build robust and safe DeFi platform, than its a given to make sure that underlying protocol as safe as possible. Nothing deserves more trust than BTC decentralisation"
          />
          <InfoBox
            title="No Ponzi Tokenomics"
            text="Token is to enable community governance, to lead the development and iteration of the platform. A reward to early adopters, but not a time locking Ponzi pool."
          />
          <InfoBox
            title="No BS features. Fundamental DeFi"
            text="Recent DEXs product iterations focus on powerusers, and finetuning of the trades, which requires more complicated infrastructure that leads to centralization."
          />
          <InfoBox className={s.span} title="Roadmap">
            <Pipeline />
          </InfoBox>
        </div>
      </div>
    </MainLayout>
  );
};

export default LandingPage;

import { Box, IconButton } from '@chakra-ui/react';
import React from 'react';

import s from './AppFooter.module.scss';
import { Icon } from '@app/components/Icon';
import { config } from '@app/config';

export const AppFooter: React.FC = () => {
  return (
    <Box className={s.root}>
      <Box className={s.wrapper}>
        <div className={s.linksWrapper}>
          {/*<div className={s.links}>*/}
          {/*  <span className={s.link}>Privacy Policy</span>*/}
          {/*  <span className={s.link}>Terms and Conditions</span>*/}
          {/*</div>*/}
          <div className={s.copyright}>© 2024 CODEX</div>
        </div>

        <div className={s.content}>
          <a href={config.telegram} target="_blank" rel="noreferrer">
            <IconButton aria-label="telegram" className={s.social}>
              <Icon name="socialTelegram" />
            </IconButton>
          </a>
          <a href={config.github} target="_blank" rel="noreferrer">
            <IconButton aria-label="github" className={s.social}>
              <Icon name="github" />
            </IconButton>
          </a>
        </div>
      </Box>
    </Box>
  );
};

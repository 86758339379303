import { Button } from '@chakra-ui/react';
import Link from 'next/link';
import Image from 'next/image';
import React, { FC, PropsWithChildren } from 'react';

import appLogo from '@app/assets/images/logo/appLogo.png';

import s from './AppHeader.module.scss';
import { config } from '@app/config';

export const AppHeader: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className={s.root}>
      <div className={s.wrapper}>
        <Link href="/" className={s.logo}>
          <Image src={appLogo} height={37} alt="App logo" />
        </Link>
        <div className={s.desktop}>{children}</div>
        <a href={config.appUrl} target="_blank" rel="noreferrer">
          <Button variant="primary">Launch App</Button>
        </a>
      </div>
    </div>
  );
};

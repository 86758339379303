import React from 'react';

import s from './Pipeline.module.scss';
import { Icon } from '@app/components/Icon';
import clsx from 'clsx';

export const Pipeline = () => {
  function renderMilestone(
    label: string,
    isFirst: boolean,
    isBottom: boolean,
    isActive: boolean
  ) {
    return (
      <div
        className={clsx(s.milestone, {
          [s.first]: isFirst,
          [s.bottom]: isBottom,
          [s.active]: isActive
        })}
      >
        <div className={s.label}>{label}</div>
        <Icon name="milestone" className={s.icon} />
      </div>
    );
  }

  return (
    <div className={s.root}>
      {renderMilestone('Dex Launch', true, true, true)}
      {renderMilestone('Token Launch', false, false, false)}
      {renderMilestone('Fiat On/Off Ramps', false, true, false)}
      {renderMilestone('Private DeFi (zkp)', false, false, false)}
      {renderMilestone('Own Wallet', false, true, false)}
    </div>
  );
};

import React, { FC } from 'react';

import s from './Supporters.module.scss';
import clsx from 'clsx';

import S1 from 'src/assets/images/supporters/s1.svg';
import S2 from 'src/assets/images/supporters/s2.svg';
import S3 from 'src/assets/images/supporters/s3.svg';
import S4 from 'src/assets/images/supporters/s4.svg';

interface Props {
  className?: string;
}

export const Supporters: FC<Props> = ({ className }) => {
  return (
    <div className={clsx(s.root, className)}>
      <h2 className={s.heading}>Supported by:</h2>
      <div className={s.wrapper}>
        <a
          className={s.item}
          href="https://www.defisafety.com/"
          target="_blank"
          rel="noreferrer"
        >
          <S1 />
        </a>
        <a
          className={s.item}
          href="https://www.adaptframework.solutions/"
          target="_blank"
          rel="noreferrer"
        >
          <S2 />
        </a>
        <a
          className={s.item}
          href="http://coredao.org/"
          target="_blank"
          rel="noreferrer"
        >
          <S3 />
        </a>
        <a
          className={s.item}
          href="https://magicpowered.io/"
          target="_blank"
          rel="noreferrer"
        >
          <S4 />
        </a>
      </div>
    </div>
  );
};

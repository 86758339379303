import React, { FC, PropsWithChildren } from 'react';

import s from './InfoBox.module.scss';
import clsx from 'clsx';

interface Props extends PropsWithChildren {
  title?: string;
  text?: string;
  className?: string;
}

export const InfoBox: FC<Props> = ({ title, text, children, className }) => {
  return (
    <div className={clsx(s.root, className)}>
      {title && <div className={s.title}>{title}</div>}
      {text && <div className={s.text}>{text}</div>}
      <div className={s.centralize}>{children}</div>
    </div>
  );
};
